<template>
  <div class="remove-member p-4">
    <div class="row m-0 font-weight-bold" style="font-size: 20px; color: #000000">
      <p>社員を削除する</p>
    </div>
    <p>以下の{{ employeeNumbers.length }}名を削除します。本当によろしいですか？</p>
    <div style="height: 120px; overflow-y: auto">
      <div>
        <ul v-for="(item, idx) in employeeNumbers" :key="idx" style="height: ">
          <li>{{ item.full_name }}({{ item.employee_number }})</li>
        </ul>
      </div>
    </div>
    <div class="row m-0 mt-4 button justify-content-end">
      <button class="btn cancel-btn" @click="cancelDelete">キャンセル</button>
      <button class="ml-4 btn delete-btn" @click="deleteMember">削除</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsCookie from "js-cookie";

export default {
  props: {
    employeeNumbers: Array,
  },

  data() {
    return {
      userIdList: [],
      token: jsCookie.get("token").split("|"),
    };
  },

  methods: {
    cancelDelete() {
      this.$emit("onCancel");
    },

    deleteMember() {
      this.userIdList = this.employeeNumbers.map((x) => x.id);
      this.userIdList.forEach((userId) => {
        let that = this;
        axios.defaults.headers.common = {
          Authorization: "Bearer " + that.token[1],
          "X-Api-Key": that.$store.state.headers["x-api-key"],
        };

        axios
          .delete(that.$store.state.serverUrl + `profile/v1/${userId}`)
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data.result);
            }
          })
          .catch();
      });
      this.$emit("onDelete", this.employeeNumbers);
    },
  },
};
</script>

<style scoped>
.remove-member {
  position: absolute;
  left: 30%;
  top: 23%;
  width: 607px;
  min-height: 209px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #000000;
}
.btn {
  width: 115px;
  min-height: 32px;
  border-radius: 5px;
  font-size: 16px;
}

.cancel-btn {
  color: #948c8c;
  background: #ffffff;
  border: 1px solid #c4c4c4;
}

.delete-btn {
  color: #ffffff;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
