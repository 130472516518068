<template>
  <div class="info-tab">
    <b-card no-body>
      <b-tabs active-nav-item-class="font-weight-bold active-nav" content-class="mt-3 pl-3">
        <b-tab title="プロフィール" active class="employee-profile">
          <div class="row m-0 align-items-center flex-column">
            <img id="avatar" :src="userAvatar" width="139px" height="150px" />
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref="fileInput"
              @change="loadAvatar($event)"
            />
            <a @click="selectAvatarImage" class="upload-img-text">画像をアップロードする</a>
            <p v-if="isWrongAvatarFormat" class="text-danger m-0 mt-2" style="font-size: 11px">
              ファイル拡張子が正しくありません。
            </p>
          </div>
          <div class="row m-0 flex-column mt-4 pr-3">
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">社員番号</p>
              </div>
              <div class="col-8 input-box">
                <input class="w-100 border-0" v-model="employee_number" />
              </div>
            </div>
            <div class="row m-0 my-3" style="height: 24px">
              <div class="col-4">
                <p class="m-0">メールアドレス</p>
              </div>
              <div class="col-8 input-box">
                <input class="w-100 border-0" v-model="email" />
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">名前</p>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-5 input-box mr-3">
                    <input class="w-100 border-0" v-model="employee_first_name" placeholder="姓" />
                  </div>
                  <div class="col-5 input-box ml-3">
                    <input class="w-100 border-0" v-model="employee_last_name" placeholder="名" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">ふりがな</p>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-5 input-box mr-3">
                    <input
                      class="w-100 border-0"
                      v-model="employee_first_name_kana"
                      placeholder="せい"
                    />
                  </div>
                  <div class="col-5 input-box ml-3">
                    <input
                      class="w-100 border-0"
                      v-model="employee_last_name_kana"
                      placeholder="めい"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">雇用形態</p>
              </div>
              <div class="col-8 input-box">
                <select class="w-100 border-0" style="height: 100%" v-model="recruit_form">
                  <option
                    v-for="(item, idx) in recruitFormList"
                    :key="idx"
                    :selected="recruit_form"
                  >
                    {{ item.recruitFormName }}
                  </option>
                </select>
                <!-- <input class="w-100 border-0" v-model="recruit_form" /> -->
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">権限</p>
              </div>
              <div class="col-8 input-box">
                <select class="w-100 border-0" style="height: 100%" v-model="role">
                  <option v-for="(item, idx) in roleList" :key="idx" :selected="role">
                    {{ item.roleName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">誕生日 / 満年齢</p>
              </div>
              <div class="col-8 input-box input-group">
                <div class="input-group d-flex align-items-center">
                  <date-picker
                    class="w-100 border-0"
                    v-model="birth_date"
                    format="yyyy/M/d"
                    ref="birthDate"
                  ></date-picker>
                  <b-icon-calendar style="position: absolute; left: 120" @click="selectBirthDate" />
                  <span style="position: absolute; right: 20px">{{ old }}</span>
                </div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">入社日</p>
              </div>
              <div class="col-8 input-box">
                <div class="input-group d-flex align-items-center">
                  <date-picker
                    class="w-100 border-0"
                    v-model="hire_date"
                    format="yyyy/M/d"
                    ref="hireDate"
                  ></date-picker>
                  <b-icon-calendar style="position: absolute; right: 0" @click="selectHireDate" />
                </div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col-4">
                <p class="m-0">使用車両</p>
              </div>
              <div class="col-8 input-box">
                <select class="w-100 border-0" style="height: 100%" v-model="vehicle_used">
                  <option v-for="(vehicle, idx) in vehicleList" :key="idx" :selected="vehicle_used">
                    {{ vehicle.vehicle_name + "(" + vehicle.vehicle_license_number + ")" }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row m-0 my-3" style="height: 40px">
              <div class="col-4">
                <p class="m-0">担当エリア</p>
              </div>
              <div class="col-8 input-box d-flex align-items-center">
                <multiselect
                  class="multi-select"
                  v-if="areaList"
                  v-model="response_area"
                  :options="areaList"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :searchable="false"
                  :show-labels="false"
                  :internal-search="false"
                  placeholder=""
                  ref="areaResponse"
                >
                  <template slot="tag" slot-scope="{ option }">
                    <div class="multiselect-tag">
                      <span>{{ option }}</span>
                      <span>,</span>&nbsp;
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="row m-0 my-3" style="height: 24px">
              <div class="col-4 pr-0">
                <p class="m-0">次回免許更新日</p>
              </div>
              <div class="col-8 input-box">
                <div class="input-group d-flex align-items-center">
                  <date-picker
                    class="w-100 border-0"
                    v-model="renewal_license"
                    format="yyyy/M/d"
                    ref="renewalDate"
                  ></date-picker>
                  <b-icon-calendar
                    style="position: absolute; right: 0"
                    @click="selectRenewalDate"
                  />
                </div>
              </div>
            </div>
            <div class="row m-0 my-3 flex-column" style="border-bottom: 0.5px solid #b6b1b1">
              <div class="col">
                <p>免許証画像</p>
              </div>
              <div class="col text-center">
                <img id="license" :src="userLicenseImg" width="321px" height="174px" />
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  ref="licenseInput"
                  @change="loadLicense($event)"
                />
                <p class="m-0 mt-3">
                  <a @click="selectLicenseImage" class="upload-img-text">画像をアップロードする</a>
                </p>
                <p v-if="isWrongLicenseFormat" class="text-danger m-0 mt-2" style="font-size: 11px">
                  ファイル拡張子が正しくありません。
                </p>
              </div>
            </div>
            <div
              class="row m-0 flex-column"
              style="border-bottom: 0.5px solid #b6b1b1; word-wrap: break-word"
            >
              <div class="row m-0 mb-2">
                <p class="col m-0">備考</p>
              </div>
              <div class="row m-0">
                <textarea class="col w-100 border-0" v-model="remarks" />
              </div>
            </div>
          </div>

          <div class="row m-0 justify-content-end pr-3 my-3">
            <button class="update-btn" @click="updateData">更新</button>
          </div>
        </b-tab>

        <b-tab title="運行実績"> </b-tab>

        <template #tabs-end>
          <b-nav-item class="close-item" role="presentation" @click.prevent="closeTab">
            <b>
              <b-icon-x></b-icon-x>
            </b>
          </b-nav-item>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import avatar from "./../../assets/user-default.png";
import licenseImg from "./../../assets/license-img.png";
import DatePicker from "vuejs-datepicker";
import axios from "axios";
import jsCookie from "js-cookie";
import multiselect from "vue-multiselect";

export default {
  props: {
    employeeInfo: null,
  },

  components: { DatePicker, multiselect },

  watch: {
    employeeInfo: {
      handler() {
        this.setData();
      },
    },

    birth_date: {
      handler(newVal) {
        this.old = new Date().getFullYear() - new Date(newVal).getFullYear() + "歳";
      },
    },
  },

  data() {
    return {
      avatar,
      licenseImg,
      isWrongAvatarFormat: false,
      isWrongLicenseFormat: false,
      employee_number: "",
      employee_first_name: "",
      employee_last_name: "",
      old: "",
      hire_date: "",
      vehicle_used: "",
      response_area: "",
      renewal_license: "",
      remarks: "",
      token: jsCookie.get("token").split("|"),
      userAvatar: null,
      userLicenseImg: null,
      email: null,
      employee_first_name_kana: null,
      employee_last_name_kana: null,
      recruit_form: null,
      role: null,
      birth_date: null,
      vehicleList: null,
      areaList: null,
      areas: false,
      roleList: [
        { key: 0, roleName: "管理者" },
        { key: 1, roleName: "ドライバー" },
      ],
      recruitFormList: [
        { key: 0, recruitFormName: "正社員" },
        { key: 1, recruitFormName: "契約社員" },
        { key: 2, recruitFormName: "派遣社員" },
        { key: 3, recruitFormName: "アルバイト、パート" },
      ],
    };
  },

  mounted() {
    this.setData();
    this.getVehicleList();
  },

  methods: {
    //set data to display when open detail tab
    setData() {
      if (this.employeeInfo.avatar_img_path !== null) {
        this.userAvatar = "data:image/(png|jpg|jpeg);base64," + this.employeeInfo.avatar_img_path;
      } else {
        this.userAvatar = avatar;
      }
      if (this.employeeInfo.license_img_path !== null) {
        this.userLicenseImg =
          "data:image/(png|jpg|jpeg);base64," + this.employeeInfo.license_img_path;
      } else {
        this.userLicenseImg = licenseImg;
      }
      this.employee_number = this.employeeInfo.employee_number;
      this.email = this.employeeInfo.email;
      this.employee_first_name = this.employeeInfo.first_name;
      this.employee_last_name = this.employeeInfo.last_name;
      this.employee_first_name_kana = this.employeeInfo.first_name_kana;
      this.employee_last_name_kana = this.employeeInfo.last_name_kana;
      this.recruit_form = this.employeeInfo.recruit_form_name;
      this.role = this.employeeInfo.role === "0" ? "管理者" : "ドライバー";
      this.birth_date = this.employeeInfo.birth_date;
      this.old = this.employeeInfo.age;
      this.hire_date = this.employeeInfo.join_company_date;
      this.vehicle_used = this.employeeInfo.vehicle;
      this.response_area = this.employeeInfo.area_response;
      this.renewal_license = this.employeeInfo.next_license_renewal_date;
      this.remarks = this.employeeInfo.remark;
    },

    //when click calendar icon on 入社日 input
    selectBirthDate() {
      this.$refs.birthDate.showCalendar();
    },

    //when click calendar icon on 入社日 input
    selectHireDate() {
      this.$refs.hireDate.showCalendar();
    },

    //when click calendar icon on 次回免許証更新日 input
    selectRenewalDate() {
      this.$refs.renewalDate.showCalendar();
    },

    //when click link 画像をアップロードする under avatar
    selectAvatarImage() {
      this.$refs.fileInput.click();
    },

    //when click link 画像をアップロードする under license image
    selectLicenseImage() {
      this.$refs.licenseInput.click();
    },

    //load image from device
    loadAvatar(event) {
      var avatar = document.getElementById("avatar");

      //check image format
      if (
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/jpg"
      ) {
        return (this.isWrongAvatarFormat = true);
      }
      window.avatar_img_path = event.target.files[0];

      this.isWrongAvatarFormat = false;
      avatar.src = URL.createObjectURL(event.target.files[0]);
      avatar.onload = function () {
        URL.revokeObjectURL(avatar.src); // free memory
      };
    },

    //load image from device
    loadLicense(event) {
      var license = document.getElementById("license");
      //check image format
      if (
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/jpg"
      ) {
        return (this.isWrongLicenseFormat = true);
      }

      window.license_img_path = event.target.files[0];

      this.isWrongLicenseFormat = false;
      license.src = URL.createObjectURL(event.target.files[0]);
      license.onload = function () {
        URL.revokeObjectURL(license.src);
      };
    },

    //when click button x on tab header
    closeTab() {
      this.$emit("onCloseTab");
    },

    //format date time
    formatDate(value) {
      const date = new Date(value);

      return date.toLocaleDateString("ja-JP");
    },

    //when click update button
    updateData() {
      //get used vehicle id
      const vehicleUsed = this.vehicleList.filter((x) => {
        let vehicleFormat = `${x.vehicle_name}(${x.vehicle_license_number})`;
        return vehicleFormat === this.vehicle_used;
      });

      // get area response id list
      var areaIdList = [];
      if (this.response_area) {
        let areaResponse = this.areas.map((x) => {
          if (this.response_area.includes(x.area_name)) {
            return x.area_id;
          }
        });

        areaResponse.forEach((id) => {
          if (id !== undefined) {
            areaIdList.push({
              area_id: id,
            });
          }
        });
      }

      const newData = {
        id: this.employeeInfo.id,
        employee_number: this.employee_number,

        //data to update DB
        tenant_id: jsCookie.get("tenantId"),
        email: this.email,
        first_name: this.employee_first_name,
        last_name: this.employee_last_name,
        first_name_kana: this.employee_first_name_kana,
        last_name_kana: this.employee_last_name_kana,
        recruit_form: this.getRecruitFormId(this.recruit_form),
        birth_date: this.formatDate(this.birth_date),
        join_company_date: this.formatDate(this.hire_date),
        vehicle_id: vehicleUsed.length !== 0 ? vehicleUsed[0]?.vehicle_id : null,
        vehicle_next_license_renewal_date: this.formatDate(this.renewal_license),
        areas: areaIdList,
        remarks: this.remarks,
        role: this.role === "ドライバー" ? "1" : "0",
      };

      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      let that = this;
      axios
        .put(this.$store.state.serverUrl + `profile/v1/${this.employeeInfo.id}`, newData)
        .then(function () {
          if (window.avatar_img_path || window.license_img_path) {
            that.updateImg();
          }else{
            that.$emit("onUpdate", that.employeeInfo.id);
          }
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.message));
        });
    },

    updateImg() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const data = new FormData();
      data.append("avatar_img_path", window.avatar_img_path);
      data.append("license_img_path", window.license_img_path);
      let that = this;
      axios
        .post(this.$store.state.serverUrl + `profile/v1/${this.employeeInfo.id}`, data)
        .then(function (response) {
          if (response.status === 200) {
            that.$emit("onUpdate", that.employeeInfo.id);
          }
        });
    },

    //get list vehicle
    getVehicleList() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      let that = this;
      axios
        .get(this.$store.state.serverUrl + `profile/v1?tenant_id=${jsCookie.get("tenantId")}`)
        .then(function (response) {
          that.vehicleList = response.data.result.vehicles;
          that.areas = response.data.result.areas;
          that.areaList = that.areas.map((x) => x.area_name);
        });
    },

    //get recruit form name
    getRecruitFormId(recruitName) {
      switch (recruitName) {
        case "正社員":
          return "0";
        case "契約社員":
          return "1";
        case "派遣社員":
          return "2";
        default:
          return "3";
      }
    },
  },
};
</script>

<style scoped>
/* setting profile tab height for each screen height */
@media (min-height: 871px) {
  .employee-profile {
    height: 74.5vh;
    overflow-y: auto;
  }
}
@media (max-height: 870px) and (min-height: 661px) {
  .employee-profile {
    height: 68vh;
    overflow-y: auto;
  }
}

@media (max-height: 660px) and (min-height: 531px) {
  .employee-profile {
    height: 60vh;
    overflow-y: auto;
  }
}

@media (max-height: 530px) {
  .employee-profile {
    height: 55vh;
    overflow-y: auto;
  }
}

/* setting nav-link width for each screen width */
@media (min-width: 1731px) {
  .tabs >>> .nav-link {
    width: 205px;
  }
}

@media (max-width: 1730px) and (min-width: 1664px) {
  .tabs >>> .nav-link {
    width: 195px;
  }
}
@media (max-width: 1663px) and (min-width: 1531px) {
  .tabs >>> .nav-link {
    width: 175px;
  }
}
@media (max-width: 1530px) and (min-width: 1231px) {
  .tabs >>> .nav-link {
    width: 130px;
  }
}

@media (max-width: 1230px) {
  .tabs >>> .nav-link {
    width: 120px;
  }
}

.info-tab {
  width: 30%;
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #000000;
  font-family: "Roboto";
}
.tabs >>> .active-nav {
  border-bottom: 7px solid #8bdff9 !important;
}
.tabs >>> .nav-link:hover {
  border: none;
}
.tabs >>> .nav-link {
  color: #000000;
  text-align: center;
  border-radius: 0;
  font-size: 16px;
}
.nav-item >>> .nav-link {
  width: 90px !important;
  text-align: right;
}

.bi-x {
  width: 1.7rem;
  height: 1.7rem;
}
.input-box {
  border: none;
  border-bottom: 0.5px solid #b6b1b1;
  padding-left: 30px;
}

.vdp-datepicker >>> input:focus,
select:focus,
input:focus {
  outline: none;
}

.vdp-datepicker >>> input {
  border: none;
  width: 100%;
}

.card {
  border-bottom: none;
  border-bottom-left-radius: 0;
}
.update-btn {
  color: #ffffff;
  font-size: 16px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  width: 115px;
}

input[type="file"] {
  display: none !important;
}

.upload-img-text {
  color: #001aff;
  margin-top: 10px;
}

.upload-img-text:hover {
  color: #172ce6;
  text-decoration: underline;
}

textarea {
  height: 120px;
  resize: none;
}

textarea:focus {
  outline: none;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
