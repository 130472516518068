<template>
  <div class="add-employee p-4">
    <div class="row m-0">
      <div class="col-6">
        <span class="font-weight-bold" style="font-size: 20px">社員を追加する</span>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button class="close-btn" @click="closeRegister"><b-icon-x /></button>
      </div>
    </div>
    <div class="row m-0 mt-2 regist-body">
      <div class="row m-0 w-100">
        <div class="col-4">
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            ref="fileInput"
            @change="loadAvatar($event)"
          />
          <button
            class="add-avt-img-btn"
            @click="selectImage"
            :style="{ 'background-image': `url(${userAvatar})` }"
          >
            <p v-if="userAvatar === null"><b-icon-plus /></p>
            <p v-if="userAvatar === null" class="text-left pl-3">
              プロフィール画像を登録してください。
            </p>
          </button>
          <a v-if="isWrongAvatarFormat" class="text-danger" style="font-size: 11px"
            >ファイル拡張子が正しくありません。</a
          >
        </div>
        <div class="col-8">
          <div class="flex-column mb-4">
            <p>社員番号</p>
            <div class="input-group">
              <input class="w-100 info-input" v-model="employee_number" />
            </div>
          </div>
          <div class="flex-column mb-4">
            <p>メールアドレス</p>
            <div class="input-group">
              <input class="w-100 info-input" v-model="email" />
            </div>
          </div>
          <div class="flex-column">
            <p>名前</p>
            <div class="row">
              <div class="col-6">
                <input class="w-100 mb-4 info-input" v-model="first_name" placeholder="姓" />
              </div>
              <div class="col-6">
                <input class="w-100 mb-4 info-input" v-model="last_name" placeholder="名" />
              </div>
            </div>
          </div>
          <div class="flex-column">
            <p>ふりがな</p>
            <div class="row">
              <div class="col-6">
                <input
                  class="w-100 mb-4 info-input"
                  v-model="katakana_first_name"
                  placeholder="せい"
                />
              </div>
              <div class="col-6">
                <input
                  class="w-100 mb-4 info-input"
                  v-model="katakana_last_name"
                  placeholder="めい"
                />
              </div>
            </div>
          </div>
          <div class="flex-column mb-4">
            <div class="row">
              <div class="col-6">
                <p>雇用形態</p>
                <div class="input-group info-input">
                  <select class="w-100 border-0" style="height: 100%" v-model="recruit_form">
                    <option v-for="(item, idx) in recruitFormList" :key="idx" :value="item.key">
                      {{ item.recruitFormName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <p>権限</p>
                <div class="input-group info-input">
                  <select class="w-100 border-0" style="height: 100%" v-model="role">
                    <option v-for="(item, idx) in roleList" :key="idx" :value="item.key">
                      {{ item.roleName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col-8">
          <p>誕生日</p>
          <div class="info-input input-group d-flex align-items-center">
            <date-picker
              class="w-100 border-0"
              v-model="birthday"
              format="yyyy/M/d"
              @input="calcAge"
              ref="birthday"
            ></date-picker>
            <b-icon-calendar style="position: absolute; right: 0" @click="selectBirthday" />
          </div>
        </div>
        <div class="col-4">
          <p>満年齢</p>
          <input class="w-100 info-input" disabled v-model="full_age" />
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>入社日</p>
          <div class="info-input input-group d-flex align-items-center">
            <date-picker
              class="w-100 border-0"
              v-model="hire_date"
              format="yyyy/M/d"
              ref="hireDate"
            ></date-picker>
            <b-icon-calendar style="position: absolute; right: 0" @click="selectHireDate" />
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>使用車両</p>
          <div class="input-group info-input">
            <select class="w-100 border-0" style="height: 100%" v-model="vehicle_used">
              <option v-for="(vehicle, idx) in vehicleList" :key="idx" :selected="vehicle_used">
                {{ vehicle.vehicle_name + "(" + vehicle.vehicle_license_number + ")" }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>担当エリア</p>
          <div class="d-flex align-items-center info-input">
            <multiselect
              class="multi-select w-100"
              v-if="areaList"
              v-model="res_area"
              :options="areaList"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"
              :searchable="false"
              :show-labels="false"
              :internal-search="false"
              placeholder=""
              ref="areaResponse"
            >
              <template slot="tag" slot-scope="{ option }">
                <div class="multiselect-tag">
                  <span>{{ option }}</span>
                  <span>,</span>&nbsp;
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>免許証</p>
          <div class="d-flex align-items-center flex-column">
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref="licenseInput"
              @change="loadLicense($event)"
            />
            <button
              class="add-license-img-btn"
              @click="selectLicenseImage"
              :style="{ 'background-image': `url(${licenseImage})` }"
            >
              <p v-if="licenseImage === null"><b-icon-plus /></p>
              <p v-if="licenseImage === null">免許証画像を登録してください。</p>
            </button>
            <p v-if="isWrongLicenseFormat" class="text-danger m-0 mt-2" style="font-size: 11px">
              ファイル拡張子が正しくありません。
            </p>
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>次回免許証更新日</p>
          <div class="info-input input-group d-flex align-items-center">
            <date-picker
              class="w-100 border-0"
              v-model="next_license_renew"
              format="yyyy/M/d"
              ref="renewalDate"
            ></date-picker>
            <b-icon-calendar style="position: absolute; right: 0" @click="selectRenewalDate" />
          </div>
        </div>
      </div>
      <div class="row m-0 w-100 mb-4">
        <div class="col">
          <p>備考</p>
          <textarea class="w-100 info-input" v-model="remarks" />
        </div>
      </div>
    </div>
    <div class="row m-0 mt-3 justify-content-end">
      <button class="regist-button" @click="registMember">登録</button>
    </div>
    <confirm-modal
      :show="confirmModal.show"
      :title="confirmModal.title"
      :message="confirmModal.message"
      @onSubmit="onSubmitRequest"
      @onCancel="onCancelModal"
    />
  </div>
</template>

<script>
import jsCookie from "js-cookie";
import DatePicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import confirmModal from "../../components/ConfirmModal.vue";
import axios from "axios";
import multiselect from "vue-multiselect";

export default {
  components: { DatePicker, confirmModal, multiselect },

  computed: {
    ...mapGetters({
      confirmModal: "employee/confirmModal",
    }),
  },

  mounted() {
    setTimeout(() => this.onCancelModal(), 0.0001);
    this.clickOther();
    this.getAreaAndVehicleList();
  },

  data() {
    return {
      userAvatar: null,
      licenseImage: null,
      first_name: "",
      last_name: "",
      katakana_first_name: "",
      katakana_last_name: "",
      birthday: "1990-1-1",
      full_age: null,
      hire_date: null,
      vehicle_used: "",
      res_area: null,
      next_license_renew: null,
      remarks: "",
      isWrongAvatarFormat: false,
      isWrongLicenseFormat: false,
      closeRegisterMsg: "編集中の内容が破棄されますがよろしいですか。",
      registMemberMsg: "この内容で登録します。よろしいですか。",
      displayMsg: "",
      recruit_form: null,
      role: null,
      employee_number: null,
      email: null,
      token: jsCookie.get("token").split("|"),
      roleList: [
        { key: 0, roleName: "管理者" },
        { key: 1, roleName: "ドライバー" },
      ],
      recruitFormList: [
        { key: 0, recruitFormName: "正社員" },
        { key: 1, recruitFormName: "契約社員" },
        { key: 2, recruitFormName: "派遣社員" },
        { key: 3, recruitFormName: "アルバイト、パート" },
      ],
      areaList: null,
      vehicleList: null,
    };
  },
  methods: {
    //close register tab when click other element
    clickOther() {
      window.addEventListener("mousedown", (e) => {
        const addEmployeeDiv = document.querySelector(".add-employee");
        if (
          e.target !== document.querySelector(".add-employee") &&
          !addEmployeeDiv?.contains(e.target)
        ) {
          this.closeRegister();
        }
      });
    },

    //calculator age when choose birthday
    calcAge() {
      this.full_age = new Date().getFullYear() - new Date(this.birthday).getFullYear() + "歳";
    },

    //when click button 'x' or click outside register modal
    closeRegister() {
      const payload = {
        show: true,
        message: this.closeRegisterMsg,
      };
      this.displayMsg = this.closeRegisterMsg;
      this.$store.dispatch("employee/showConfirmModal", payload);
    },

    //format date
    formatDate(value) {
      if (value !== null) {
        const date = new Date(value);

        return date.toLocaleDateString("ja-JP");
      } else {
        return null;
      }
    },

    // when click register button
    registMember() {
      const payload = {
        show: true,
        message: this.registMemberMsg,
      };
      this.displayMsg = this.registMemberMsg;
      this.$store.dispatch("employee/showConfirmModal", payload);
    },

    // when click button 'はい' on confirm modal after click register button
    onRequestRegist() {
      //get used vehicle id
      const vehicleUsed = this.vehicleList.filter((x) => {
        let vehicleFormat = `${x.vehicle_name}(${x.vehicle_license_number})`;
        return vehicleFormat === this.vehicle_used;
      });

      // get area response id list
      var areaIdList = [];
      if (this.res_area) {
        let areaResponse = this.areas.map((x) => {
          if (this.res_area.includes(x.area_name)) {
            return x.area_id;
          }
        });

        areaResponse.forEach((id) => {
          if (id !== undefined) {
            areaIdList.push({
              area_id: id,
            });
          }
        });
      }

      var newEmployee = {
        tenant_id: jsCookie.get("tenantId"),
        employee_number: this.employee_number,
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        first_name_kana: this.katakana_first_name,
        last_name_kana: this.katakana_last_name,
        avatar_img_path: null,
        recruit_form: this.recruit_form,
        birth_date: this.formatDate(this.birthday),
        join_company_date: this.formatDate(this.hire_date),
        vehicle_id: vehicleUsed[0]?.vehicle_id,
        license_img_path: null,
        areas: areaIdList,
        vehicle_next_license_renewal_date: this.formatDate(this.next_license_renew),
        remarks: this.remarks,
        role: this.role,
      };

      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      let that = this;
      axios.post(that.$store.state.serverUrl + "profile/v1", newEmployee).then(function (response) {
        if (response.status === 200) {
          that.updateImg(response.data.result.user_id);
        }
      });
    },

    updateImg(id) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const data = new FormData();
      data.append("avatar_img_path", window.avatar_img_path);
      data.append("license_img_path", window.license_img_path);
      let that = this;
      axios.post(this.$store.state.serverUrl + `profile/v1/${id}`, data).then(function (response) {
        if (response.status === 200) {
          that.$emit("onRegister");
        }
      });
    },

    // when click button 'はい' on confirm modal when click 'x' button
    onCloseRegist() {
      this.$emit("onCloseRegister");
    },

    // when click button 'はい' on confirm modal
    onSubmitRequest() {
      this.onCancelModal();
      if (this.displayMsg === this.registMemberMsg) {
        this.onRequestRegist();
      } else {
        this.onCloseRegist();
      }
    },

    // when click button 'いいえ' on confirm modal
    onCancelModal() {
      const payload = {
        show: false,
      };
      this.$store.dispatch("employee/showConfirmModal", payload);
    },

    // when click calendar button in 誕生日 input
    selectBirthday() {
      this.$refs.birthday.showCalendar();
    },

    // when click calendar button in 入社日 input
    selectHireDate() {
      this.$refs.hireDate.showCalendar();
    },

    // when click calendar button in 次回免許証更新日 input
    selectRenewalDate() {
      this.$refs.renewalDate.showCalendar();
    },

    // select avatar image
    selectImage() {
      this.$refs.fileInput.click();
    },

    // load image from device
    loadAvatar() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (
        file[0].type !== "image/png" &&
        file[0].type !== "image/jpeg" &&
        file[0].type !== "image/jpg"
      ) {
        return (this.isWrongAvatarFormat = true);
      }
      window.avatar_img_path = file[0];
      this.isWrongAvatarFormat = false;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.userAvatar = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },

    // select license image
    selectLicenseImage() {
      this.$refs.licenseInput.click();
    },

    // load image from device
    loadLicense() {
      let input = this.$refs.licenseInput;
      let file = input.files;
      if (
        file[0].type !== "image/png" &&
        file[0].type !== "image/jpeg" &&
        file[0].type !== "image/jpg"
      ) {
        return (this.isWrongLicenseFormat = true);
      }
      window.license_img_path = file[0];

      this.isWrongLicenseFormat = false;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.licenseImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },

    //get vehicle and area list
    getAreaAndVehicleList() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      let that = this;
      axios
        .get(this.$store.state.serverUrl + `profile/v1?tenant_id=${jsCookie.get("tenantId")}`)
        .then(function (response) {
          that.vehicleList = response.data.result.vehicles;
          that.areas = response.data.result.areas;
          that.areaList = that.areas.map((x) => x.area_name);
        });
    },
  },
};
</script>

<style scoped>
@media (max-height: 710px) and (min-height: 610px) {
  .regist-body {
    height: 55vh;
    overflow-y: auto;
  }
}

@media (max-height: 609px) {
  .regist-body {
    height: 50vh;
    overflow-y: auto;
  }
}
.add-employee {
  position: absolute;
  left: 28%;
  top: 9%;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  border-radius: 10px;
  width: 40%;
}

.regist-body {
  overflow-y: auto;
  max-height: 60vh;
}

.close-btn {
  right: 0;
  border: none;
  background: transparent;
}
.bi-x {
  width: 35px;
  height: 35px;
}

.add-avt-img-btn {
  background: #ffffff;
  border: 1px dashed #000000;
  border-radius: 10px;
  width: 100%;
  height: 50%;
  background-size: cover;
  background-position: center center;
}
.bi-plus {
  width: 30px;
  height: 30px;
}

.input-group {
  align-items: center;
}

.info-input {
  border: none;
  border-bottom: 1px solid #b6b1b1;
}

.vdp-datepicker >>> input:focus,
select:focus,
.info-input:focus {
  outline: none;
}

.info-input::placeholder {
  color: #b6b1b1;
}

.vdp-datepicker >>> input {
  border: none;
  width: 100%;
}

.regist-button {
  color: #ffffff;
  font-size: 16px;
  width: 115px;
  height: 32px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
}
.add-license-img-btn {
  width: 351px;
  height: 169px;
  border: 1px dashed #000000;
  border-radius: 10px;
  background: #ffffff;
}

input[type="file"] {
  display: none !important;
}

input:disabled {
  background: transparent;
}

textarea {
  resize: none;
}

.multi-select >>> li {
  list-style-type: none;
}

.multi-select >>> .multiselect__input {
  width: 100% !important;
  position: inherit !important;
  border: 0;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
