<template>
  <div class="px-4" style="font-family: 'Roboto'; color: #000000">
    <div class="row m-0 flex-row align-items-center float-right">
      <button class="filter-btn"></button>
      <i class="search-icon"></i>
      <input
        type="search"
        v-model="keySearch"
        @keyup.enter="getEmployeeData(1)"
        class="search-input"
      />
      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :margin-pages="0"
        :page-range="0"
        :first-last-button="false"
        :clickHandler="handlePageSelected"
      ></paginate>
      <nav v-if="pageCount === 0">
        <ul class="pagination mb-0">
          <li class="page-item disabled">
            <button tabindex="-1" class="page-link">〈</button>
          </li>
          <li class="page-item disabled">
            <button tabindex="0" class="page-link">〉</button>
          </li>
        </ul>
      </nav>
      <span class="recordNum" v-if="!isTotalData">
        {{ displayDataNumberFrom }} - {{ displayDataNumberTo }} / {{ totalEmployee }} 件</span
      >
      <span class="recordNum" v-else> {{ displayDataNumberTo }} / {{ totalEmployee }} 件</span>
    </div>
    <div class="row m-0 w-100">
      <button
        class="p-2 add-member-btn d-flex justify-content-center align-items-center"
        @click="
          registerMember = true;
          removeMember = false;
        "
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 21V17C2 15.8954 2.89543 15 4 15H14C15.1046 15 16 15.8954 16 17V21"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 11H22M19 8V14V8Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-white pl-2">社員を追加する</span>
      </button>
      <button
        class="p-2 ml-3 remove-member-btn d-flex justify-content-center align-items-center"
        @click="openRemoveTab"
        :disabled="!isRemoved"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
            :stroke="setSvgColor()"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 21V17C2 15.8954 2.89543 15 4 15H14C15.1046 15 16 15.8954 16 17V21"
            :stroke="setSvgColor()"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 11H22"
            :stroke="setSvgColor()"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="pl-2">社員を削除する</span>
      </button>
    </div>
    <div class="row m-0 w-100 mt-2">
      <div class="employee-data-table" style="height: 81vh; overflow-y: auto">
        <b-table :items="items" :fields="fields" class="border mb-0">
          <template v-slot:head(checkbox)>
            <input
              type="checkbox"
              class="table-checkbox"
              :checked="isAllChecked"
              @change="checkAllCheckbox"
            />
          </template>
          <template v-slot:cell(checkbox)="{ item }">
            <input
              type="checkbox"
              class="table-checkbox"
              :id="'checkbox' + parseInt(item.employee_number)"
              v-model="item.isChecked"
            />
          </template>
          <template v-slot:cell(edit)="{ item }">
            <button class="btn edit-btn" @click="openDataDetailTab(item)"></button>
          </template>
          <template v-slot:cell(area_response)="{ item }">
            <p v-for="(area, id) in item.area_response" :key="id" class="p-0 m-0">
              {{ area }}
            </p>
          </template>
        </b-table>
      </div>
      <detail-data-tab
        v-if="isOpenDetailTab"
        :employeeInfo="detailData"
        @onCloseTab="closeDataDetailTab"
        @onUpdate="updateEmployeeData"
      />
    </div>
    <add-employee
      v-if="registerMember"
      @onCloseRegister="closeRegisterTab"
      @onRegister="onRegisterMember"
    />
    <remove-employee
      v-if="removeMember"
      @onCancel="cancelDelete"
      :employeeNumbers="numberEmpList"
      @onDelete="deleteMember"
    />
  </div>
</template>

<script>
import addEmployee from "../views/EmployeeManage/AddEmployee.vue";
import removeEmployee from "../views/EmployeeManage/RemoveEmployee.vue";
import detailDataTab from "../views/EmployeeManage/EmployeeDetailTab.vue";
import Paginate from "../components/Paginate.vue";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  mounted() {
    this.isRemoved = false;
    document.getElementsByClassName("employee-data-table")[0].style.width =
      "-webkit-fill-available";
    this.getEmployeeData(1); // get data on page 1
  },

  components: { Paginate, addEmployee, removeEmployee, detailDataTab },

  data() {
    return {
      isRemoved: false,
      isAllChecked: false,
      registerMember: false,
      removeMember: false,
      numberEmpList: [],
      isOpenDetailTab: false,
      detailData: null,
      token: Cookies.get("token").split("|"),
      fields: [
        { key: "checkbox", label: "" },
        { key: "edit", label: "編集" },
        { key: "employee_number", label: "社員番号" },
        { key: "full_name", label: "名前" },
        { key: "recruit_form_name", label: "雇用形態" },
        { key: "age", label: "満年齢" },
        { key: "join_company_date", label: "入社日" },
        { key: "vehicle", label: "使用車両" },
        { key: "area_response", label: "担当エリア" },
        { key: "next_license_renewal_date", label: "次回免許証更新日" },
        { key: "remark", label: "備考" },
      ],
      items: [],
      userId: null,
      totalEmployee: 0,
      //number of data on a page
      dataPerPage: 20,
      //number of displayed data
      displayDataNumberFrom: 1,
      displayDataNumberTo: 20,
      isTotalData: false,
      currentPage: 1,
      keySearch: null,
      pageCount: 1,
    };
  },

  methods: {
    //get employee data
    async getEmployeeData(page) {
      this.currentPage = page;
      this.items = [];
      //let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const { data } = await axios.get(this.$store.state.serverUrl + `profile/v1/${this.userId}`, {
        params: {
          tenant_id: Cookies.get("tenantId"),
          key_search: this.keySearch,
          offset: (page - 1) * this.dataPerPage,
          limit: this.dataPerPage,
        },
      });

      let results = data.result.users;

      //pagination
      const total = data.result.employee_number_count;
      this.totalEmployee = total;

      this.pageCount = Math.ceil(total / this.dataPerPage);
      this.displayDataNumberTo = total < this.dataPerPage * page ? total : this.dataPerPage * page;
      this.displayDataNumberFrom = page <= 1 ? 1 : this.dataPerPage * (page - 1) + 1;
      this.isTotalData = this.dataPerPage * (page - 1) + 1 === total;

      results.forEach((element) => {
        let age = this.getAge(element.birth_date);
        const subArray = {
          id: element.user_id,
          isChecked: false,
          employee_number: element.employee_number,
          full_name: element.first_name + " " + element.last_name,
          recruit_form_name: this.getRecruitFormName(element.recruit_form),
          age: age !== null ? age + "歳" : null,
          join_company_date: this.formatDate(element.join_company_date),
          vehicle:
            element.vehicle_name !== null
              ? element.vehicle_name + "(" + element.vehicle_license_number + ")"
              : null,
          area_response: element.areas.map((x) => x.area_name),
          next_license_renewal_date: this.formatDate(element.vehicle_next_license_renewal_date),
          remark: element.remarks,
          avatar_img_path: element.avatar_img_path,
          birth_date: element.birth_date,
          email: element.email,
          license_img_path: element.license_img_path,
          role: element.role,
          full_kana_name:
            element.first_name_kana === null
              ? null
              : element.first_name_kana + " " + element.last_name_kana,
          first_name_kana: element.first_name_kana,
          last_name_kana: element.last_name_kana,
          first_name: element.first_name,
          last_name: element.last_name,
        };

        // push data to items array
        this.items.push(subArray);
      });
    },

    //get recruit form name
    getRecruitFormName(recruitId) {
      switch (recruitId) {
        case "0":
          return "正社員";
        case "1":
          return "契約社員";
        case "2":
          return "派遣社員";
        default:
          return "アルバイト、パート";
      }
    },

    //format date
    formatDate(value) {
      if (value !== null) {
        const date = new Date(value);
        return date.toLocaleDateString("ja-JP");
      } else {
        return null;
      }
    },

    //when change page
    handlePageSelected(page) {
      this.currentPage = page;
      this.getEmployeeData(this.currentPage);
    },

    getAge(dateString) {
      if (dateString !== null) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      } else {
        return null;
      }
    },

    //set color to remove button icon
    setSvgColor() {
      if (this.isRemoved) {
        return "#000000";
      } else {
        return "#b6b1b1";
      }
    },

    // open delete tab
    openRemoveTab() {
      this.removeMember = true;
      const newItems = this.items.filter((x) => x.isChecked);
      this.numberEmpList = [...newItems];
      this.registerMember = false;
    },

    //check/uncheck all checkbox in table
    checkAllCheckbox() {
      const status = !this.isAllChecked;
      this.isAllChecked = status;
      const newItems = this.items.map((x) => {
        return {
          ...x,
          isChecked: status,
        };
      });
      this.items = [...newItems];
    },

    // close register tab
    closeRegisterTab() {
      this.registerMember = false;
    },

    // register new member
    onRegisterMember() {
      this.closeRegisterTab();
      this.getEmployeeData(1); // get data and return to page 1
    },

    //click button cancel on delete tab
    cancelDelete() {
      this.removeMember = false;
    },

    //delete member
    deleteMember(value) {
      this.closeDataDetailTab();
      const newItems = this.items.filter((x) => !value.includes(x));
      this.items = [...newItems];
      this.removeMember = false;
    },

    // when click button to open detail tab
    openDataDetailTab(item) {
      this.isOpenDetailTab = true;
      this.removeMember = false;
      this.registerMember = false;
      this.detailData = item;

      document.getElementsByClassName("employee-data-table")[0].style.width = "70.6%";
    },

    //close detail tab
    closeDataDetailTab() {
      this.isOpenDetailTab = false;

      document.getElementsByClassName("employee-data-table")[0].style.width =
        "-webkit-fill-available";
    },

    //update data
    async updateEmployeeData(id) {
      this.closeDataDetailTab();
      await this.getEmployeeData(1); // get data and return to page 1

      const adminId = window.atob(Cookies.get("userId")).split("=")[1];
      if (id === Number(adminId)) {
        let data = this.items.filter((x) => x.id === id);
        localStorage.setItem("avatarImg", data[0].avatar_img_path);
        location.reload();
      }
    },
  },

  watch: {
    items: {
      handler(newValue) {
        this.isRemoved = newValue.some((x) => x.isChecked);
        this.isAllChecked = !newValue.some((x) => !x.isChecked);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.filter-btn {
  background: url("../assets/filter-icon.png");
  width: 24px;
  height: 24px;
  border: none;
  margin-right: 25.83px;
}
.search-icon {
  background: url("../assets/search-icon.png");
  width: 22px;
  height: 21px;
  margin-right: -21px;
  z-index: 1;
}
.form-outline {
  border-bottom: 0.5px solid #b6b1b1;
}
.search-input {
  width: 206px;
  border: none;
  border-bottom: 0.5px solid #b6b1b1;
  border-radius: 0px;
  padding-left: 25px;
}
.search-input:focus {
  outline: none;
  box-shadow: none;
}
.previous-btn {
  color: #b6b1b1;
  background: transparent;
}
.next-btn {
  color: #000000;
  background: transparent;
}
.recordNum {
  font-weight: 400;
  font-size: 12px;
}

.add-member-btn {
  width: 172px;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  font-size: 14px;
}
.remove-member-btn {
  color: #000000;
  width: 172px;
  background: #ffffff;
  border: 0.5px solid #000000;
  border-radius: 5px;
  font-size: 14px;
}

.add-member-btn:disabled {
  background: #b6b1b1;
}
.remove-member-btn:disabled {
  color: #b6b1b1;
  width: 172px;
  background: #ffffff;
  border: 0.5px solid #b6b1b1;
  border-radius: 5px;
  font-size: 14px;
}

.b-table >>> th {
  font-weight: normal;
  background: rgba(0, 0, 0, 0.03);
  color: #000000;
  border-bottom: none;
  vertical-align: top;
}

.b-table >>> td {
  color: #000000;
  font-size: 15px;
}

.table-checkbox {
  min-width: 19px;
  min-height: 19px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
}

.edit-btn {
  width: 24px;
  height: 24px;
  background: url("../assets/edit-icon.png");
}

input.search-input[type="search"]::-webkit-search-decoration,
input.search-input[type="search"]::-webkit-search-cancel-button,
input.search-input[type="search"]::-webkit-search-results-button,
input.search-input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.page-item.disabled .page-link {
  color: #b6b1b1;
  border: none;
}
/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.multiselect__select {
  width: auto;
  padding: 0 3px 10px 0;
}

.multiselect__select::before {
  color: #000000;
  margin-top: 0;
  border-color: #999 transparent transparent;
  content: "\f078";
  font: normal normal normal 11px/1 FontAwesome;
  border-width: 0;
}

.multiselect--active .multiselect__select {
  transform: none;
}

.multiselect__tag {
  background: none;
  color: #000000;
  position: relative;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 0;
}

.multiselect__tag-icon {
  display: none;
}

.multiselect__option {
  color: #000000;
}

.multiselect__option--highlight {
  background: none !important;
  color: #000000 !important;
}

.multiselect__tags-wrap {
  display: inline-flex;
  color: #000000;
}
.multiselect__content-wrapper {
  border-top: 1px solid #c4c4c4;
}
</style>
